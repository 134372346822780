/*********************************************************
    // abstracts
**********************************************************/
/*********************************************************
    // base
**********************************************************/
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 17, 2023 */
@font-face {
  font-family: "tt_firs_neuedemibold";
  src: url("sass/base/font/ttfirsneue-demibold-webfont.woff2") format("woff2"), url("sass/base/font/ttfirsneue-demibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tt_firs_neuemedium";
  src: url("sass/base/font/ttfirsneue-medium-webfont.woff2") format("woff2"), url("sass/base/font/ttfirsneue-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tt_firs_neueregular";
  src: url("sass/base/font/ttfirsneue-regular-webfont.woff2") format("woff2"), url("sass/base/font/ttfirsneue-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tt_firs_neuebold";
  src: url("sass/base/font/ttfirsneue-bold-webfont.woff2") format("woff2"), url("sass/base/font/ttfirsneue-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/*********************************************************
    // layout
**********************************************************/
/*********************************************************
    // components
**********************************************************/
/*********************************************************
    // pages
**********************************************************/
/********** Template CSS **********/
body {
  font-family: "tt_firs_neueregular", sans-serif !important;
}

:root {
  --primary: #3f3f42;
  --secondary: #a4a4a6;
  --light: #f7faff;
  --dark: #1d1d27;
  --footer-bg: #282828;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Heading ***/
h1,
h2,
h3,
.fw-bold {
  font-family: "tt_firs_neuemedium";
}

h4,
h5,
h6,
.fw-medium {
  font-family: "tt_firs_neuemedium";
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: 0.5s;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}

/*** Navbar ***/
.navbar-light .navbar-nav .nav-link {
  position: relative;
  margin-left: 25px;
  padding: 0 0;
  color: var(--dark) !important;
  outline: none;
  transition: 0.5s;
}

.sticky-top.navbar-light .navbar-nav .nav-link {
  padding: 0 0;
  color: var(--dark) !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--dark) !important;
}

.navbar-light .navbar-brand h1 {
  color: #ffffff;
}

.navbar-light .navbar-brand img {
  max-height: 60px;
  transition: 0.5s;
}

.sticky-top.navbar-light .navbar-brand img {
  max-height: 45px;
}

.navbar a i {
  font-size: 25px;
  padding: 25px;
}

@media (max-width: 991.98px) {
  .sticky-top.navbar-light {
    position: relative;
    background: #ffffff;
  }
  .navbar-light .navbar-collapse {
    margin-top: 15px;
    border-top: 1px solid #dddddd;
  }
  .navbar-light .navbar-nav .nav-link,
  .sticky-top.navbar-light .navbar-nav .nav-link {
    padding: 10px 0;
    margin-left: 0;
    color: var(--dark) !important;
  }
  .navbar-light .navbar-brand h1 {
    color: var(--dark) !important;
  }
  .navbar-light .navbar-brand img {
    max-height: 45px;
  }
}
@media (min-width: 992px) {
  .navbar-light {
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .sticky-top.navbar-light {
    position: fixed;
    background: #ffffff;
  }
  .navbar-light .navbar-nav .nav-link::before {
    position: absolute;
    content: "";
    width: 0;
    height: 2px;
    bottom: -1px;
    left: 50%;
    background: var(--dark) !important;
    transition: 0.5s;
  }
  .navbar-light .navbar-nav .nav-link:hover::before,
  .navbar-light .navbar-nav .nav-link.active::before {
    width: 100%;
    left: 0;
  }
  .navbar-light .navbar-nav .nav-link.nav-contact::before {
    display: none;
  }
  .sticky-top.navbar-light .navbar-brand h1 {
    color: var(--primary);
  }
}
/*** Hero Header ***/
.hero-header {
  background: url(../images/placeholder.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*** Section Title ***/
.section-title.text-center::before {
  left: 50%;
  margin-left: -25px;
}

.section-title.text-center::after {
  left: 50%;
  margin-left: 25px;
}

.section-title h6::before,
.section-title h6::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 2px;
  left: 0;
  background: rgba(33, 66, 177, 0.5);
}

.section-title h6::after {
  top: 5px;
  left: 3px;
}

/*** Service ***/
.service-item {
  position: relative;
  height: 350px;
  padding: 30px 25px;
  background: #ffffff;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
}

.service-item:hover {
  background: var(--primary);
}

.service-item .service-icon {
  margin: 0 auto 20px auto;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  background: url(../images/icon-shape-primary.png) center center no-repeat;
  transition: 0.5s;
}

.service-item:hover .service-icon {
  color: var(--primary);
  background: url(../images/icon-shape-white.png);
}

.service-item h5,
.service-item p {
  transition: 0.5s;
}

.service-item:hover h5,
.service-item:hover p {
  color: var(--light);
}

.service-item a.btn {
  position: relative;
  display: flex;
  color: var(--primary);
  transition: 0.5s;
  z-index: 1;
}

.service-item:hover a.btn {
  color: var(--primary);
}

.service-item a.btn::before {
  position: absolute;
  content: "";
  width: 35px;
  height: 35px;
  top: 0;
  left: 0;
  border-radius: 35px;
  background: #dddddd;
  transition: 0.5s;
  z-index: -1;
}

.service-item:hover a.btn::before {
  width: 100%;
  background: var(--light);
}

/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item.center .testimonial-item * {
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  background: var(--light) !important;
  border-color: var(--light);
}

.testimonial-carousel .owl-item.center .testimonial-item i {
  color: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item h6 {
  color: var(--dark) !important;
}

/*** Team ***/
.team-item {
  position: relative;
  transition: 0.5s;
  z-index: 1;
}

.team-item::after {
  position: absolute;
  content: "";
  top: 3rem;
  right: 3rem;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  z-index: -1;
}

.team-item:hover::after {
  background: var(--primary);
}

.team-item h5,
.team-item small {
  transition: 0.5s;
}

.team-item:hover h5,
.team-item:hover small {
  color: var(--light);
}

/*** Project Portfolio ***/
#portfolio-flters .btn {
  position: relative;
  display: inline-block;
  margin: 10px 4px 0 4px;
  transition: 0.5s;
  border-radius: 0 !important;
}

#portfolio-flters .btn:hover,
#portfolio-flters .btn.active {
  color: var(--light);
  background: var(--primary);
}

.portfolio-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  top: 0;
  left: 0;
  background: var(--primary);
  transition: 0.5s;
  z-index: 1;
  opacity: 0;
}

.portfolio-item:hover .portfolio-overlay {
  opacity: 0.8;
}

.portfolio-item .btn {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: 0.5s;
  opacity: 0;
  z-index: 2;
  border-radius: 0;
}

.portfolio-item:hover .btn {
  opacity: 1;
  transition-delay: 0.15s;
}

/*** Contact ***/
.contact-section {
  background-color: #f5f5f3;
}
.contact-section h2 {
  margin-top: -70px !important;
}

/*** Footer ***/
.footer {
  background-size: contain;
  background-color: var(--footer-bg) !important;
}
.footer .btn-sm {
  font-size: 40px;
  color: white;
  padding: 60px 30px;
  cursor: pointer;
  transition: 0.5s;
}
.footer .btn-sm:hover {
  padding: 60px 60px;
}

.footer .btn.btn-link {
  display: block;
  padding: 0;
  text-align: left;
  color: var(--light);
  font-family: "tt_firs_neueregular";
  transition: 0.3s;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 15px 20px;
  font-size: 12px;
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}