/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 17, 2023 */

@font-face {
	font-family: 'tt_firs_neuedemibold';
	src: url('sass/base/font/ttfirsneue-demibold-webfont.woff2') format('woff2'),
		url('sass/base/font/ttfirsneue-demibold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'tt_firs_neuemedium';
	src: url('sass/base/font/ttfirsneue-medium-webfont.woff2') format('woff2'),
		url('sass/base/font/ttfirsneue-medium-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'tt_firs_neueregular';
	src: url('sass/base/font/ttfirsneue-regular-webfont.woff2') format('woff2'),
		url('sass/base/font/ttfirsneue-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'tt_firs_neuebold';
	src: url('sass/base/font/ttfirsneue-bold-webfont.woff2') format('woff2'),
		url('sass/base/font/ttfirsneue-bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
